/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import loadinggif from './../../assets/gif/lorrygif.gif'
import ReactToPrint from 'react-to-print';
// reactstrap components
import ReactDatetime from "react-datetime";
import { Autocomplete } from '@material-ui/lab';
import { Checkbox } from 'react-input-checkbox';
import TextField from '@material-ui/core/TextField';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import Moment from 'moment';
import axios from 'axios';
//import RNPrint from 'react-native-print';
class filemanager extends React.Component {
  constructor(props) {
    super(props);
    this.printButtonRef = React.createRef();
  }

  state = {
    
    //linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
    linkurl:"https://staging.mewahtrans.com.my/api/mewahapicode.php?",
     //linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
     //linkurl:"https://crm.mewahtrans.com.my/api/webbaseservice.php?",
     //linkurl:"https://demostaging.mewahtrans.com.my/api/mewahapicode.php?",
    loadingmodal:false,
    aftersubmitupdatestatus:0,
    tabs: 2,
    exampleModal: false,
    editpopuout:false,
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    Usernamehere:sessionStorage.getItem('Ref'),
    quotationtabledata:[],
    quotationtabledatafinal:[],
    detailbilltoparty:'',
    detailshipper:'',
    detailaddress:'',
    detailarea:'',
    detailpic:'',
    detaildepo:'',
    detailcontact:'',
    detailbacktobackcharge:'',
    pguprice20gp:'',
    pguprice20rf:'',
    pguprice20isotank:'',
    pguprice20ot:'',
    pguprice20tipper:'',
    pguprice40gp:'',
    pguprice40rf:'',
    pguprice40isotank:'',
    pguprice40tipper:'',
    pguprice40ot:'',
    
    ptpprice20gp:'',
    ptpprice20rf:'',
    ptpprice20isotank:'',
    ptpprice20ot:'',
    ptpprice20tipper:'',
    ptpprice40gp:'',
    ptpprice40rf:'',
    ptpprice40isotank:'',
    ptpprice40tipper:'',
    ptpprice40ot:'',


    editbilltoparty:'',
    editshipper:'',
    editaddress:'',
    editarea:'',
    editpic:'',
    editdepo:'',
    editcontact:'',
    editbacktobackcharge:'',
    editpguprice20gp:'',
    editpguprice20rf:'',
    editpguprice20isotank:'',
    editpguprice20ot:'',
    editpguprice20tipper:'',
    editpguprice40gp:'',
    editpguprice40rf:'',
    editpguprice40isotank:'',
    editpguprice40tipper:'',
    editpguprice40ot:'',
    
    editptpprice20gp:'',
    editptpprice20rf:'',
    editptpprice20isotank:'',
    editptpprice20ot:'',
    editptpprice20tipper:'',
    editptpprice40gp:'',
    editptpprice40rf:'',
    editptpprice40isotank:'',
    editptpprice40tipper:'',
    editptpprice40ot:'',

    buttoncolor1:'white',
    buttoncolor2:'priamry',

    quotationnumberclicked:'',
    quotationnumberforupdate:'',
    arealist:[],
    currentquotationpagenumber:1,
    searchtextvalue:'',
    popoutemail:false,
    popoutcancelorpending:false,
    filemanagerlist:[],
    completelist:[],
    pagenumber:1, 
  };
 

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    if(index == 2){
      this.quotationbuttonclick();
    }else if(index ==1){
      this.quotationbuttonclickpending();
    }
    this.setState({
      [state]: index
    });
    
      
  
  };
 
  toggleModal = (state,shipperclicked,quotationnumber,status) => {
    if(state === "exampleModal"){
      //console.log("trigger function detail")
      //console.log(quotationnumbertestest)
     
   
      if(shipperclicked == null){
        this.setState({loading:false})
      }else{
        if(status=="Cancelled"){
          this.quotationdetailfinalclickpendingorcancelled(shipperclicked,quotationnumber,status);
          this.setState({popoutcancelorpending:false})
        }else{
          this.quotationdetailfinalclick(shipperclicked,quotationnumber);
          this.setState({popoutcancelorpending:true})
        }
      }
    }else if(state==="editpopuout"){
      console.log("trigger function editpopuout")
     
      
      if(shipperclicked == null){
        this.setState({loading:false})
      }else{
        this.getarealist();
        this.quotationdetailfinalclick(shipperclicked,quotationnumber);
      }
    }else if(state==="exampleModal2"){
      //console.log("trigger function detail example modal2")
      console.log(quotationnumber)
      //console.log("Status: " + status)
       
     // this.setState({quotationtabledata:[]})
     if(shipperclicked == null){
      this.setState({loading:false})
    }else{
      this.quotationdetailfinalclickpendingorcancelled(shipperclicked,quotationnumber,status);
    }
     

    }
    
    this.setState({
      [state]: !this.state[state]
    });
  };

  toggleModalwithfunctionpopoutemail = (openclosetruefalse) =>{
    this.setState({popoutemail:openclosetruefalse})       
  }

  handleChange(event,stateid) {
    if(stateid === "detailbilltoparty"){
      this.setState({detailbilltoparty: event});
    } else if(stateid == "detailshipper"){
      this.setState({detailshipper: event});
    }else if(stateid == "detailaddress"){
      this.setState({detailaddress: event});
    }else if(stateid == "detailarea"){
      this.setState({detailarea: event});
    }else if(stateid == "detaildepo"){
      this.setState({detaildepo: event});
    }else if(stateid == "detailsideloadercharge"){
      this.setState({detailsideloadercharge: event});
    }
    else if(stateid == "detailpic"){
      this.setState({detailpic: event});
    }else if(stateid == "detailcontact"){
      this.setState({detailcontact: event});
    }else if(stateid == "pguprice20gp"){
      this.setState({pguprice20gp: event});
    }else if(stateid == "pguprice20isotank"){
      this.setState({pguprice20isotank: event});
    }else if(stateid == "pguprice20ot"){
      this.setState({pguprice20ot: event});
    }else if(stateid == "pguprice20rf"){
      this.setState({pguprice20rf: event});
    }else if(stateid == "pguprice20tipper"){
      this.setState({pguprice20tipper: event});
    }else if(stateid == "pguprice40gp"){
      this.setState({pguprice40gp: event});
    }else if(stateid == "pguprice40isotank"){
      this.setState({pguprice40isotank: event});
    }else if(stateid == "pguprice40rf"){
      this.setState({pguprice40rf: event});
    }else if(stateid == "pguprice40tipper"){
      this.setState({pguprice40tipper: event});
    }else if(stateid == "pguprice40ot"){
      this.setState({pguprice40ot: event});
    }else if(stateid == "ptpprice20gp"){
      this.setState({ptpprice20gp: event});
    }else if(stateid == "ptpprice20isotank"){
      this.setState({ptpprice20isotank: event});
    }else if(stateid == "ptpprice20ot"){
      this.setState({ptpprice20ot: event});
    }else if(stateid == "ptpprice20rf"){
      this.setState({ptpprice20rf: event});
    }else if(stateid == "ptpprice20tipper"){
      this.setState({ptpprice20tipper: event});
    }else if(stateid == "ptpprice40gp"){
      this.setState({ptpprice40gp: event});
    }else if(stateid == "ptpprice40isotank"){
      this.setState({ptpprice40isotank: event});
    }else if(stateid == "ptpprice40tipper"){
      this.setState({ptpprice40tipper: event});
    }else if(stateid == "ptpprice40rf"){
      this.setState({ptpprice40rf: event});
    }else if(stateid == "ptpprice40ot"){
      this.setState({ptpprice40ot: event});
    }
    
  }
 
  getlasteditbywho = () =>{
    
    //this.setState({searchtextvalue:''})
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    let link = this.state.linkurl + 'mode=admin-getlasteditbywho'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log("getlasteditbywholist: ")
            console.log(json)
             var lengthemployee = Object.keys(json.user).length;
             var indexofvalue = -1
             for(let i=0;i<lengthemployee;i++){


              for ( let k=0;k<this.state.quotationtabledatafinal.length;k++){
                if(this.state.quotationtabledatafinal[k].lastedit === json.user[i].Ref){
                  this.state.quotationtabledatafinal[k].lasteditname =  json.user[i].Name
                  this.state.quotationtabledatafinal[k].profileimg = json.user[i].Profilepictureurl
                  console.log("json.user[i].Name:"  + json.user[i].Name)
                  console.log("profilepictureurl: " + json.user[i].Profilepictureurl)
                  console.log("profilepictureurl1: " + this.state.quotationtabledatafinal[k].profileimg)
                }else if(this.state.quotationtabledatafinal[k].lastedit == 0){
                  this.state.quotationtabledatafinal[k].profileimg = "0.jpg"
                }
              }

              for ( let l=0;l<this.state.quotationtabledata.length;l++){
                if(this.state.quotationtabledata[l].lastedit === json.user[i].Ref){
                  this.state.quotationtabledata[l].lasteditname =  json.user[i].Name
                  this.state.quotationtabledata[l].profileimg = json.user[i].Profilepictureurl
                  console.log("json.user[i].Name:"  + json.user[i].Name)
                  console.log("profilepictureurl: " + json.user[i].Profilepictureurl)
                  console.log("profilepictureurl1: " + this.state.quotationtabledata[l].profileimg)
                }else if(this.state.quotationtabledata[l].lastedit == 0){
                  this.state.quotationtabledata[l].profileimg = "0.jpg"
                }
              }
              // if(this.state.quotationtabledatafinal.some(item => item.lastedit === json.user[i].Ref)){          
                  
              //   var filterobj = this.state.quotationtabledatafinal.find(function(item, j){
              //     if(item.lastedit === json.user[i].Ref){
              //       indexofvalue = j;
              //     }
              //   });
              //   //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
              //   this.state.quotationtabledatafinal[indexofvalue].lasteditname =  json.user[i].Name
              //   console.log("name: " + this.state.quotationtabledatafinal[indexofvalue].lasteditname)
              // }
             
              // if(this.state.quotationtabledata.some(item => item.lastedit === json.user[i].Ref)){          
                  
              //   var filterobj = this.state.quotationtabledata.find(function(item, j){
              //     if(item.lastedit === json.user[i].Ref){
              //       indexofvalue = j;
              //     }
              //   });
              //   //var index = this.state.rotpendinglist.indexOf(item => item.rotno === json.user[i].Rotnumber)
              //   this.state.quotationtabledata[indexofvalue].lasteditname =  json.user[i].Name
             
              // }
            }   
            this.setState({loading:false})
          }     
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })

     console.log(this.state.quotationtabledatafinal)
  }
  
  quotationbuttonclick = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({currentquotationpagenumber:1})
    this.setState({searchtextvalue:''})
    //this.setState({searchtextvalue:''})
    this.setState({loading:true})
    this.setState({tabs:2})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl + 'mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  shippernametoshow:json.user[i].Shipper.slice(0,30),
                  quotationnumber:json.user[i].Quotationnumber,
                  lastedit:json.user[i].LastEdit,
                  lasteditname:'',
                  lastview:(json.user[i].Lastview-28800)*1000,
                  profileimg:'',
                },            
              ]})
              
              this.setState({["expand" + i]:0})
             
             //  console.log(this.state.quotationtabledatafinal)
             

             
              }
              this.setState({quotationlistcounttotal: Object.keys(this.state.quotationtabledatafinal).length});
              console.log(this.state.quotationlistcounttotal)
              this.state.quotationtabledatafinal.sort((a, b) => (b.shippername - a.shippername))
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
      this.getlasteditbywho()
     })
  }

  quotationdetailfinalclick = (shipperclicked,quotationnumberclicked) =>{
    // console.log(this.state.Billtoparty)
    // console.log(shipperclicked)
    // console.log(quotationnumberclicked)
    this.setState({loadingmodal:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("Shipper", shipperclicked);
    urlencoded.append("quotationnumber", quotationnumberclicked);
    
    let link = this.state.linkurl +  'mode=admin-getquotationlistfinaldetail'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({
              detailbilltoparty:'',
              detailshipper:'',
              detailaddress:'',
              detailarea:'',
              detailpic:'',
              detaildepo:'',
              detailcontact:'',
              detailbacktobackcharge:'',
              detailsideloadercharge:'',
              pguprice20gp:'',
              pguprice20rf:'',
              pguprice20isotank:'',
              pguprice20ot:'',
              pguprice20tipper:'',
              pguprice40gp:'',
              pguprice40rf:'',
              pguprice40isotank:'',
              pguprice40tipper:'',
              pguprice40ot:'',
              
              ptpprice20gp:'',
              ptpprice20rf:'',
              ptpprice20isotank:'',
              ptpprice20ot:'',
              ptpprice20tipper:'',
              ptpprice40gp:'',
              ptpprice40rf:'',
              ptpprice40isotank:'',
              ptpprice40tipper:'',
              ptpprice40ot:'',
            });   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({
                detailbilltoparty:json.user[0].billtoparty,
                detailshipper:json.user[0].Shipper,
                detailaddress:json.user[0].Address,
                detailarea:json.user[0].Area,
                detailpic:json.user[0].Personincharge,
                detaildepo:json.user[0].depogatecharge,
                detailsideloadercharge:json.user[0].sideloadercharge,
                detailcontact:json.user[0].Contact,
                //detailbacktobackcharge:json.user[0].Address,
              })
              //this.setState({quotationnumberforupdate:quotationnumberclicked})
              if(json.user[i].Pguorptp == 'PGU'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({pguprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({pguprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({pguprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({pguprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({pguprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({pguprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({pguprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({pguprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({pguprice40tipper:json.user[i].Price})
                    }else  if(json.user[i].Type == 'OT'){
                      this.setState({pguprice40ot:json.user[i].Price})
                    }
                  
                }
              }else if(json.user[i].Pguorptp == 'PTP'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({ptpprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({ptpprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({ptpprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({ptpprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({ptpprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({ptpprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({ptpprice40tipper:json.user[i].Price})
                    }else  if(json.user[i].Type == 'OT'){
                      this.setState({ptpprice40ot:json.user[i].Price})
                    }
                  
                }
              
              }
            }        
            this.setState({loadingmodal:false})     

          }else{
            this.setState({loadingmodal:false})   
        console.log(json)
      }

      
     })
  }
 
  quotationdetailfinalclickpendingorcancelled = (shipperclicked,quotationnumberclicked,status) =>{
    // console.log(this.state.Billtoparty)
    // console.log(shipperclicked)
    // console.log(quotationnumberclicked123123)
    this.setState({loadingmodal:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("Shipper", shipperclicked);
    urlencoded.append("quotationnumber", quotationnumberclicked);
    var link ='';
    //console.log("Status2: " + status);
    if(status ==='Pending'){
      link = this.state.linkurl + 'mode=admin-getquotationlistfinaldetailpending'
    }else{
      link = this.state.linkurl + 'mode=admin-getquotationlistfinaldetailcancelled'
    }
    console.log(link);
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      console.log(json)
      if(json.success == 1){
        
            
            this.setState({
              detailbilltoparty:'',
              detailshipper:'',
              detailaddress:'',
              detailarea:'',
              detailpic:'',
              detaildepo:'',
              detailcontact:'',
              detailbacktobackcharge:'',
              detailsideloadercharge:'',
              pguprice20gp:'',
              pguprice20rf:'',
              pguprice20isotank:'',
              pguprice20ot:'',
              pguprice20tipper:'',
              pguprice40gp:'',
              pguprice40rf:'',
              pguprice40isotank:'',
              pguprice40tipper:'',
              pguprice40ot:'',

              ptpprice20gp:'',
              ptpprice20rf:'',
              ptpprice20isotank:'',
              ptpprice20ot:'',
              ptpprice20tipper:'',
              ptpprice40gp:'',
              ptpprice40rf:'',
              ptpprice40isotank:'',
              ptpprice40tipper:'',
              ptpprice40ot:'',
            });   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
              this.setState({
                detailbilltoparty:json.user[0].billtoparty,
                detailshipper:json.user[0].Shipper,
                detailaddress:json.user[0].Address,
                detailarea:json.user[0].Area,
                detailpic:json.user[0].Personincharge,
                detaildepo:json.user[0].depogatecharge,
                detailsideloadercharge:json.user[0].sideloadercharge,
                detailcontact:json.user[0].Contact,
                // detailbacktobackcharge:json.user[0].Address,
              })
              this.setState({quotationnumberforupdate:quotationnumberclicked})
              if(json.user[i].Pguorptp == 'PGU'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({pguprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({pguprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({pguprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({pguprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({pguprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({pguprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({pguprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({pguprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({pguprice40tipper:json.user[i].Price})
                    }else  if(json.user[i].Type == 'OT'){
                      this.setState({pguprice40ot:json.user[i].Price})
                    }
                  
                }
              }else if(json.user[i].Pguorptp == 'PTP'){
                if(json.user[i].Size == 20){
                  if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice20gp:json.user[i].Price})
                  }else  if(json.user[i].Type == 'RF'){
                    this.setState({ptpprice20rf:json.user[i].Price})
                  }else  if(json.user[i].Type == 'ISOTANK'){
                    this.setState({ptpprice20isotank:json.user[i].Price})
                  }else  if(json.user[i].Type == 'OT'){
                    this.setState({ptpprice20ot:json.user[i].Price})
                  }else  if(json.user[i].Type == 'Tipper'){
                    this.setState({ptpprice20tipper:json.user[i].Price})
                  }
                }else{
                 
                    if(json.user[i].Type == 'GP'){
                      this.setState({ptpprice40gp:json.user[i].Price})
                    }else  if(json.user[i].Type == 'RF'){
                      this.setState({ptpprice40rf:json.user[i].Price})
                    }else  if(json.user[i].Type == 'ISOTANK'){
                      this.setState({ptpprice40isotank:json.user[i].Price})
                    }else  if(json.user[i].Type == 'Tipper'){
                      this.setState({ptpprice40tipper:json.user[i].Price})
                    }else  if(json.user[i].Type == 'OT'){
                      this.setState({ptpprice40ot:json.user[i].Price})
                    }
                  
                }
              
              }
            }        
            this.setState({loadingmodal:false})     
            this.setState({loading:false})     
          }else{
            this.setState({loadingmodal:false})  
            this.setState({loading:false})    
        //console.log(json)
      }

      
     })
  }

  getthedetailrot = (consignmentno) =>{

  }

  componentDidMount() {
    //this.quotationbuttonclickpending();
    //this.getarealist();
   // this.quotationbuttonclick();
     this.setState({pagenumber:1})
    this.getfilemanager()
  }

  downloadfile = async (filelink,consignmentno,type) =>{
    if(type == "Consignmentno"){
      this.printButtonRef.current.click();
    }else if(type=="Rot"){
        this.getthedetailrot(consignmentno)
        this.printButtonRef.current.click();

    }else{
      if(filelink ==""||filelink==null){
        window.confirm("Link error, please try other")
      }else{ 
        try {
            const response = await axios.get(filelink, {
                responseType: 'blob',
            });
            const parts = filelink.split('/');
            const filename = parts[parts.length - 1];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // You can also get filename from response headers if server provides it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file:", error);
        }
      }
    }
    
        
  }

  previousbuttonclick = () =>{
    var pagenumbernow = this.state.pagenumber
    this.setState({pagenumber:pagenumbernow - 1})
    this.getfilemanager(this.state.searchtextvalue,pagenumbernow-1)
  }
  
  nextbuttonclick = () =>{
    var pagenumbernow = this.state.pagenumber
    this.setState({pagenumber:pagenumbernow + 1})
    this.getfilemanager(this.state.searchtextvalue,pagenumbernow+1)
  }

  getfilemanager = (searchvalue,pagenumber) =>{
    // console.log(this.state.startDate)
   //  console.log(this.state.endDate)
    if(pagenumber == null){
      pagenumber = 1
    }
    console.log("Searchtext"  + searchvalue);
    var urlencoded = new URLSearchParams();
     var datestart = new Date(this.state.startDate).getDate()
     var twodigitdate = ("0" + datestart).slice(-2);
     var monthstart = new Date(this.state.startDate).getMonth() + 1
     var twodigitmonth = ("0" + monthstart).slice(-2);
     var yearstart = new Date(this.state.startDate).getFullYear()
     var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+08:00"
     var starttimetopassnotmilli = 0
     var starttimetopass = 0
     if(this.state.startDate == undefined){
       starttimetopassnotmilli = 0
       starttimetopass = 0
     }else{     
      starttimetopass = new Date(totaldatestart).getTime()
      starttimetopassnotmilli = starttimetopass/1000      
      urlencoded.append("fromdate",starttimetopassnotmilli);
     }
     

     var datestart = new Date(this.state.endDate).getDate()
     var twodigitdate = ("0" + datestart).slice(-2);
     var monthstart = new Date(this.state.endDate).getMonth() + 1
     var twodigitmonth = ("0" + monthstart).slice(-2);
     var yearstart = new Date(this.state.endDate).getFullYear()
     var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+08:00"
     
     if(this.state.endDate == undefined){
      var endtimetopassnotmilli = 0
      var endtimetopass = 0
     }else{     
      var endtimetopass = new Date(totaldatestart).getTime()
      var endtimetopassnotmilli = endtimetopass/1000
      urlencoded.append("todate",endtimetopassnotmilli);
     }

    this.setState({loading:true})
    
    urlencoded.append("Billtoparty",this.state.Billtoparty);
    urlencoded.append("id",this.state.ref);
    if(searchvalue == null || searchvalue ==""){
      urlencoded.append("searchtextvalue","");
    }else{
      console.log(searchvalue);
      urlencoded.append("searchtextvalue",searchvalue);
    }
    
    urlencoded.append("page",pagenumber);
    let link = this.state.linkurl +'mode=admin-filemanager'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      //console.log(json.success)
      console.log(json)
      if(json.success == 1){      
        this.setState({filemanagerlist:[]});   
        var lengthemployee = Object.keys(json.user).length;
       // this.setState({processtotal:Object.keys(json.user).length})
        for(let i=0;i<lengthemployee;i++){

          this.setState({filemanagerlist:[...this.state.filemanagerlist,
           { 
            no:i+1,
            consignmentno:json.user[i].Consignmentno,  
            bookingno:json.user[i].Bookingno,  
            date: Moment.unix(json.user[i].Activitydatecreatenew-28800).format("DD/MM/YYYY"),
            filelink: json.user[i].filelink,  
            filename:json.user[i].filename,  
            type:json.user[i].type,   

            volume1:json.user[i].volume1, 
            volume2:json.user[i].volume2, 
            volume3:json.user[i].volume3, 
            volume4:json.user[i].volume4, 
            container1:json.user[i].container1, 
            container2:json.user[i].container2, 
            container3:json.user[i].container3, 
            container4:json.user[i].container4, 
            container5:json.user[i].container5, 
            container6:json.user[i].container6, 
            container7:json.user[i].container7, 
            container8:json.user[i].container8, 
            remark:json.user[i].remark, 
            importexport:json.user[i].importexport, 
            shippingline:json.user[i].shippingline, 
            forwardingagent:json.user[i].forwardingagent, 
            commodity:json.user[i].commodity, 
            pod:json.user[i].pod, 
            pol:json.user[i].pol, 
            vesselvoyage:json.user[i].vesselvoyage, 
            voyagename:json.user[i].voyagename, 
            vesseleta:json.user[i].vesseleta, 
            closingdate:json.user[i].closingdate, 
            containerrequiredate:json.user[i].containerrequiredate, 
            Bookingno:json.user[i].Bookingno, 
            pickupyard:json.user[i].pickupyard, 
            shippername:json.user[i].shippername, 
            pic:json.user[i].pic, 
            shipperaddress:json.user[i].shipperaddress, 
            billingto:json.user[i].billingto, 
            contactpic:json.user[i].contactpic, 

            consignmentnoconsign:json.user[i].contactpic, 
            containernoconsign:json.user[i].containernoconsign, 
            sealnoconsign:json.user[i].sealnoconsign, 
            typenoconsign:json.user[i].typenoconsign, 
            totalvolumeconsign:json.user[i].totalvolumeconsign, 
            activitydatecreatenewconsign:json.user[i].activitydatecreatenewconsign, 
            sizenoconsign:json.user[i].sizenoconsign, 
            requestdatenoconsign:json.user[i].requestdatenoconsign, 
            operatorconsign:json.user[i].operatorconsign, 
            vsletaconsign:json.user[i].vsletaconsign, 
            bookingconsign:json.user[i].bookingconsign, 
            vesselconsign:json.user[i].vesselconsign, 
            voyageconsign:json.user[i].voyageconsign, 
            closingdaynoconsign:json.user[i].closingdaynoconsign, 
            podconsign:json.user[i].podconsign, 
            polconsign:json.user[i].polconsign, 
            sideloaderconsign:json.user[i].sideloaderconsign, 
            billtopartyconsignm:json.user[i].billtopartyconsignm, 
            shipperconsign:json.user[i].shipperconsign, 
            forwardingconsign:json.user[i].forwardingconsign, 
            pickupaddressconsign:json.user[i].pickupaddressconsign, 
            deliveryaddressconsign:json.user[i].deliveryaddressconsign, 
            contactnumberconsign:json.user[i].contactnumberconsign, 
            remarkconsign:json.user[i].remarkconsign, 
            commodityconsign:json.user[i].commodityconsign, 
            drivertruckinconsign:json.user[i].drivertruckinconsign, 
            primemoverconsign:json.user[i].primemoverconsign, 
            trailertruckinconsign:json.user[i].trailertruckinconsign, 
            datetruckinconsign:json.user[i].datetruckinconsign, 
            imageclassassigncustomertruckinconsign:json.user[i].imageclassassigncustomertruckinconsign, 
            imageclassassigndrivertruckinconsign:json.user[i].imageclassassigndrivertruckinconsign, 
            customernametruckinconsign:json.user[i].customernametruckinconsign, 
            customerphonetruckinconsign:json.user[i].customerphonetruckinconsign, 
            customerdatetruckinconsign:json.user[i].customerdatetruckinconsign, 
            drivertruckoutconsign:json.user[i].drivertruckoutconsign, 
            customerphonetruckinconsign:json.user[i].customerphonetruckinconsign, 
            primemovertruckoutconsign:json.user[i].primemovertruckoutconsign, 
            trailertruckoutconsign:json.user[i].trailertruckoutconsign, 
            pickupaddresstruckoutconsign:json.user[i].pickupaddresstruckoutconsign, 
            deliveryaddresstruckoutconsign:json.user[i].deliveryaddresstruckoutconsign, 
            datetruckoutconsign:json.user[i].datetruckoutconsign, 
            imageclassconsigncustomertruckout:json.user[i].imageclassconsigncustomertruckout, 
            imageclasssigndrivertruckout:json.user[i].imageclasssigndrivertruckout, 
            customernametruckout:json.user[i].customernametruckout, 
            customerphonetruckout:json.user[i].customerphonetruckout, 
            customerdatetruckout:json.user[i].customerdatetruckout,  
    
           },            
         ]}) 
         }
        //  this.state.filemanagerlist.sort((a,b) => (a.consignmentno < b.consignmentno) ?1:-1)
        this.setState({loading:false})
           
            
      }
      else{
        this.setState({filemanagerlist:[]})
        this.setState({loading:false})
       // console.log(json)
      }
    
     })
  }

  

  
  renderfilemanager(){
    return this.state.filemanagerlist.map((data, index) => {
      const {no,consignmentnoconsign,containernoconsign,sealnoconsign,typenoconsign,totalvolumeconsign,activitydatecreatenewconsign,sizenoconsign,
        requestdatenoconsign,operatorconsign,vsletaconsign,bookingconsign,vesselconsign,voyageconsign,closingdaynoconsign,podconsign,
        polconsign,sideloaderconsign,billtopartyconsign,shipperconsign,forwardingconsign,pickupaddressconsign,deliveryaddressconsign,
        contactnumberconsign,remarkconsign,commodityconsign,drivertruckinconsign,primemoverconsign,trailertruckinconsign,datetruckinconsign,
        imageclassassigncustomertruckinconsign,imageclassassigndrivertruckinconsign,customernametruckinconsign,customerphonetruckinconsign,
        customerdatetruckinconsign,drivertruckoutconsign,primemovertruckoutconsign,trailertruckoutconsign,pickupaddresstruckoutconsign,
        deliveryaddresstruckoutconsign,datetruckoutconsign,imageclassconsigncustomertruckout,imageclasssigndrivertruckout,customernametruckout,
        customerphonetruckout,customerdatetruckout,consignmentno,bookingno,date,filelink,filename,type,volume1,volume2,volume3,volume4,container1,container2,container3,container4,container5,container6,container7,container8,remark,importexport,shippingline,forwardingagent,commodity,pod,pol,vesselvoyage,voyagename,vesseleta,closingdate,containerrequiredate,Bookingno,pickupyard,shippername,pic,shipperaddress,billingto,contactpic} = data //destructuring
      
      
        return (
          
                 <tr>
          <td>{no}.</td>
          <th scope="row">
            <Media className="align-items-center">
              
              <Media>
                <span className="mb-0 text-sm">
                   {consignmentno}
                </span>
              </Media>
            </Media>
          </th>
          <td >{bookingno}</td>
          <td >{date}</td>
          <td >
            <Row style={{marginTop:10}}>
            {type=="Image" ?
               <div>  <img
               alt="..."
               width="80"
               height="80"   
               className="rounded-circle  mr-5"
               src={filelink}
               id={"tooltipending"+no}
             /></div>
              :
              <i className="ni ni-single-copy-04 mr-2 ml-2 " style={{marginTop:3}}/>
              }
       
          
          <span>{filename}</span>
          
            </Row>
            </td>
           
         
          <td className="text-center">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
           
              
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {type == "Rot" ?
                      <ReactToPrint  
                      trigger={() =>    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button" 
                      className="alignrightbutton"
                      onClick={(e) => console.log("Print clicked")} 
                      >
    
                      Print ROT
                      </Button>   }
                      content={() => this.state["componentRef"+no]}
                      />
                  :
                  type== "Consignmentno" ?
                    <ReactToPrint  
                    trigger={() =>    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button" 
                    className="alignrightbutton"
                    onClick={(e) => console.log("Print clicked")} 
                    >

                    Print Consignment Note
                    </Button>   }
                    content={() => this.state["componentRef2"+no]}
                    />
                  :
                  <DropdownItem
                  href="#pablo"
                  onClick={() =>  this.downloadfile(filelink,consignmentno,type)}
                >
                  Download
                </DropdownItem>
                  }
              
           
              
             </DropdownMenu>
               
               
            </UncontrolledDropdown>
          </td>
          <div style={{ display: "none" }}><ComponentToPrint 
                  volume1={volume1}
                  volume2={volume2}
                  volume3={volume3}
                  volume4={volume4}
                  size1=""
                  size2=""
                  size3=""
                  size4=""
                  type1=""
                  type2=""
                  type3=""
                  type4=""
                  container1={container1}
                  container2={container2}
                  container3={container3}
                  container4={container4}
                  container5={container5}
                  container6={container6}
                  container7={container7}
                  container8={container8}
                  remark={remark}
                  importexport={importexport}
                  shippingline={shippingline}
                  forwardingagent={forwardingagent}
                  commodity={commodity}
                  pod={pod}
                  pol={pol}
                  vesselvoyage={vesselvoyage}
                  voyagename={voyagename}
                  vesseleta={vesseleta}
                  closingdate={closingdate}
                  containerrequiredate={containerrequiredate}
                  containerrequiretime=""
                  Bookingno={Bookingno}
                  pickupyard={pickupyard}
                  shippername={shippername}
                  pic={pic}
                  shipperaddress={shipperaddress}
                  billingto={billingto}
                  contactpic={contactpic}
                    ref={el => (this.state["componentRef"+no] = el)} /></div> 
        
                      <div style={{ display: "none" }}><ComponentToPrint2
                  consignmentnoconsign={consignmentnoconsign}
                  containernoconsign={containernoconsign}
                  sealnoconsign={sealnoconsign}
                  typenoconsign={typenoconsign}
                  totalvolumeconsign={totalvolumeconsign}
                  activitydatecreatenewconsign={activitydatecreatenewconsign}
                  sizenoconsign={sizenoconsign}
                  requestdatenoconsign={requestdatenoconsign}
                  operatorconsign={operatorconsign}
                  vsletaconsign={vsletaconsign}
                  bookingconsign={bookingconsign}
                  vesselconsign={vesselconsign}
                  voyageconsign={voyageconsign}
                  closingdaynoconsign={closingdaynoconsign}
                  podconsign={podconsign}
                  polconsign={polconsign}
                  sideloaderconsign={sideloaderconsign}
                  billtopartyconsign={billtopartyconsign}
                  shipperconsign={shipperconsign}
                  forwardingconsign={forwardingconsign}
                  pickupaddressconsign={pickupaddressconsign}
                  deliveryaddressconsign={deliveryaddressconsign}
                  contactnumberconsign={contactnumberconsign}
                  remarkconsign={remarkconsign}
                  commodityconsign={commodityconsign}
                  drivertruckinconsign={drivertruckinconsign}
                  primemoverconsign={primemoverconsign}
                  trailertruckinconsign={trailertruckinconsign}
                  datetruckinconsign={datetruckinconsign}
                  imageclassassigncustomertruckinconsign={imageclassassigncustomertruckinconsign}
                  imageclassassigndrivertruckinconsign={imageclassassigndrivertruckinconsign}
                  customernametruckinconsign={customernametruckinconsign}
                  customerphonetruckinconsign={customerphonetruckinconsign}
                  customerdatetruckinconsign={customerdatetruckinconsign}
                  drivertruckoutconsign={drivertruckoutconsign}
                  primemovertruckoutconsign={primemovertruckoutconsign}
                  trailertruckoutconsign={trailertruckoutconsign}
                  datetruckoutconsign={datetruckoutconsign}
                  pickupaddresstruckoutconsign={pickupaddresstruckoutconsign}
                  deliveryaddresstruckoutconsign={deliveryaddresstruckoutconsign} 
                  imageclassconsigncustomertruckout={imageclassconsigncustomertruckout}
                  imageclasssigndrivertruckout={imageclasssigndrivertruckout}
                  customernametruckout={customernametruckout}
                  customerphonetruckout={customerphonetruckout}
                  customerdatetruckout={customerdatetruckout} 


    ref={el => (this.state["componentRef2"+no] = el)} /></div> 
                  </tr>
 

                   
        )
     
     
    })
  }


  cancelledpendingquotationlist = () =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})


    this.setState({loading:true})
    this.setState({tabs:1})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
      urlencoded.append("billtoparty", this.state.Billtoparty);
      let  link = this.state.linkurl + 'mode=admin-getquotationcancelled'
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       if(json.success == 1){
         
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
  
               var statusword = 'Cancelled'
             
               this.setState({quotationtabledata:[...this.state.quotationtabledata,
                 {no:this.state.quotationtabledata.length+ i+1,
                   shippername:json.user[i].Shipper,
                   shippernametoshow:json.user[i].Shipper.slice(0,30),
                   quotationnumber:json.user[i].Quotationnumber,
                   status:statusword,
                   lastedit:json.user[i].LastEdit,
                   lastview:(json.user[i].Lastview-28800)*1000,
                 },            
               ]})
               
               
              
               console.log(this.state.quotationtabledata)
              
  
              
               }
               //sort data here
              // this.state.quotationtabledata.sort((a, b) => (b.shippername - a.shippername))
               this.setState({loading:false}) 
             }        
                    
          else{
           this.setState({loading:false}) 
         console.log(json)
       }
     
      })
  }

  quotationbuttonclickpending = () =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})
    this.setState({searchtextvalue:''})
    //this.setState({searchtextvalue:''})
    this.setState({loading:true})
    this.setState({quotationtabledata:[]})
    this.setState({tabs:1})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl + 'mode=admin-getquotationpending'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
           
            var lengthemployee = Object.keys(json.user).length;
            for(let i=0;i<lengthemployee;i++){

            
                var statusword = 'Pending'
              
                this.setState({quotationtabledata:[...this.state.quotationtabledata,
                  {no:i+1,
                    shippername:json.user[i].Shipper,
                    shippernametoshow:json.user[i].Shipper.slice(0,30),
                    quotationnumber:json.user[i].Quotationnumber,
                    status:statusword,
                    lastedit:json.user[i].LastEdit,
                    lasteditname:'1',
                    lastview:(json.user[i].Lastview-28800)*1000,
                  },            
                ]})
                       
              
              
             
              console.log(this.state.quotationtabledata)
             

             
              }
              this.cancelledpendingquotationlist()
            }        
                   
         else{
          this.setState({loading:false}) 
          this.cancelledpendingquotationlist()
        console.log(json)
      }
      this.getlasteditbywho()
     })



   

  }

  getarealist = () =>{
    this.setState({loading:true})
    const temparealist = [];
    var urlencoded = new URLSearchParams();
    let link = this.state.linkurl + 'mode=admin-getarealist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({arealist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({arealist:[...this.state.arealist,
                { no:i+1,
                  area:json.user[i].Area,
                  title:json.user[i].Area,
                },            
              ]})
              }
             // this.setState({loading:false})  
            }        
               
         else{
         // //this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
  saveandchangeeditquotationpending = () => {
    console.log("clicked")
    console.log("quotationumberforupdate: " + this.state.quotationnumberforupdate)

    this.setState({aftersubmitupdatestatus:3})
    var urlencoded = new URLSearchParams();


    urlencoded.append("quotationnumber",this.state.quotationnumberforupdate);
    urlencoded.append("billtoparty", this.state.detailbilltoparty);
    urlencoded.append("shipper",this.state.detailshipper );
    urlencoded.append("address", this.state.detailaddress);
    urlencoded.append("area", this.state.detailarea);
    urlencoded.append("pic", this.state.detailpic);
    urlencoded.append("depogatecharge", this.state.detaildepo);
    urlencoded.append("sideloadercharge", this.state.detailsideloadercharge);
    urlencoded.append("contact", this.state.detailcontact);
    urlencoded.append("backtobackcharge", this.state.detailbacktobackcharge);
    urlencoded.append("20GPUGHCpgu", this.state.pguprice20gp);
    urlencoded.append("20RFpgu", this.state.pguprice20rf);
    urlencoded.append("20ISOTANKpgu", this.state.pguprice20isotank);
    urlencoded.append("20OTpgu",this.state.pguprice20ot );
    urlencoded.append("20Tipperpgu", this.state.pguprice20tipper);
    urlencoded.append("40GPUGHCpgu", this.state.pguprice40gp);
    urlencoded.append("40RFpgu", this.state.pguprice40rf);
    urlencoded.append("40ISOTANKpgu", this.state.pguprice40isotank);
    urlencoded.append("40Tipperpgu", this.state.pguprice40tipper);
    urlencoded.append("40Otpgu", this.state.pguprice40ot);
    urlencoded.append("20GPUGHCptp", this.state.ptpprice20gp);
    urlencoded.append("20RFptp", this.state.ptpprice20rf);
    urlencoded.append("20ISOTANKptp", this.state.ptpprice20isotank);
    urlencoded.append("20OTptp", this.state.ptpprice20ot);
    urlencoded.append("20Tipperptp", this.state.ptpprice20tipper);
    urlencoded.append("40GPUGHCptp", this.state.ptpprice40gp );
    urlencoded.append("40RFptp", this.state.ptpprice40rf);
    urlencoded.append("40ISOTANKptp",this.state.ptpprice40isotank );
    urlencoded.append("40Tipperptp", this.state.ptpprice40tipper);
    urlencoded.append("40Otptp", this.state.ptpprice40ot);
    urlencoded.append("lasteditby", this.state.Usernamehere);
    let link = this.state.linkurl + 'mode=admin-updatequotationapi'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            //success
            this.setState({aftersubmitupdatestatus:2})

            //console.log(json.user)
            //  var lengthemployee = Object.keys(json.user).length;
            //  for(let i=0;i<lengthemployee;i++){

            //   //status need do
            //    this.setState({rotpendinglist:[...this.state.rotpendinglist,
            //     { no:i+1,
            //       rotno:json.user[i].Rotnumber,
            //       shippername:json.user[i].Shipper,
            //       totalvolume:json.user[i].totalvolume,
            //       bookingno:json.user[i].Bookingno,
            //       status:1,
            //       remark:json.user[i].Remark,
            //     },            
            //   ]})
              
              
             
            //    console.log(this.state.rotpendinglist)
             

             
            //   }
              this.setState({loading:false})  
            }        
               
         else{
           //fail submit
          this.setState({aftersubmitupdatestatus:4})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  closebuttonfunction=()=>{
    console.log("closebuttonfunction work")
    this.setState({aftersubmitupdatestatus:0})
    this.toggleModal("editpopuout")
    this.quotationbuttonclickpending();
  }

  deleteorcancelpendingquotation = (quotationid) =>{
    
    console.log(quotationid)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("pendingquotationid",quotationid)
     let link = this.state.linkurl + 'mode=admin-deletependingquotation'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        window.confirm('Your Quotation had cancelled success.')
        
        this.quotationbuttonclickpending();
        console.log(json)
       }        
                   
         else{
          window.confirm('Something wrong please try again later.')
        console.log(json)
      }
    
     })



  }

  renderTableData() {
      

      return this.state.quotationtabledata.map((data, index) => {
         const { shippernametoshow, no, shippername, quotationnumber, lastedit ,status,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
         const imgurllastedit = this.state.imageurllink + profileimg
         //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
        // console.log(imgurllastedit)
        
         return (

          <tr>
          <td>{no})</td>
          <th scope="row">
            <Media className="align-items-center">
              
              <Media>
                <span className="mb-0 text-sm">
                   {shippernametoshow}
                </span>
              </Media>
            </Media>
          </th>
          <td >{quotationnumber}</td>
          <td>
            <Badge color="" className="badge-dot mr-4">
              {status =="Pending"                
              ?
              <i className="bg-info" />
              :
              <i className="bg-warning" />
            }
            
              {status}
            </Badge>
          </td>
          <td className="d-md-inline-block colforlastedit">
            <div className="avatar-group d-md-inline-block">
            <a
                className="avatar avatar-sm"
                href="#pablo" 
                     
                onClick={e => e.preventDefault()}
              >
                <img
                  alt="..."
                  className="rounded-circle"
                  src={imgurllastedit}
                  id={"tooltipending"+no}
                />
              </a>             
              <UncontrolledTooltip
                delay={0}                
                placement="top"                
                target={"tooltipending"+no}
              >
                {lasteditname}
              </UncontrolledTooltip>
           
            </div>
            <div className="mt-2 text-sm d-md-inline-block position-absolute">
                   {Moment(lastview).format('DD/MM/YYYY')}
                </div>
          </td>                     
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={e => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
           
                {status =="Pending"?
                 <DropdownMenu className="dropdown-menu-arrow" right>
                 <DropdownItem
                  href="#pablo"
                  
                  onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
                >
                  View/Edit
                </DropdownItem>
              
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
                >
                  Delete
                </DropdownItem>
               
              </DropdownMenu>
                :

                <DropdownMenu className="dropdown-menu-arrow" right>
                 <DropdownItem
                href="#pablo"
                
                onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber,status)}
              >
                View
              </DropdownItem>
              
             </DropdownMenu>
               
                }
               
            </UncontrolledDropdown>
          </td>
        </tr>
         )
      })
  }

  renderTableDataphone() {
      

    return this.state.quotationtabledata.map((data, index) => {
       const { shippernametoshow, no, shippername, quotationnumber, lastedit ,status,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
       const imgurllastedit = this.state.imageurllink + profileimg
       //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
      // console.log(imgurllastedit)
      
       return (

        <div className="mt-1 cardviewbox">
           <div className="horizontaldirection">
           <h4 className="ml-4 mr-10 phoneviewcontent">{shippernametoshow}</h4>     
                     
           <p className="ml-auto mr-10 phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color=""
              onClick={e => e.preventDefault()}
            >
              <i className="fas fa-ellipsis-v" />
            </DropdownToggle>
         
              {status =="Pending"?
               <DropdownMenu className="dropdown-menu-arrow" right>
               <DropdownItem
                href="#pablo"
                
                onClick={() => this.toggleModal("exampleModal2",shippername,quotationnumber,status)}
              >
                View/Edit
              </DropdownItem>
            
              <DropdownItem
                href="#pablo"
                onClick={(e) => {if (window.confirm('Are you sure you wish to cancel this Quotation?')) this.deleteorcancelpendingquotation(quotationnumber)}}
              >
                Delete
              </DropdownItem>
             
            </DropdownMenu>
              :

              <DropdownMenu className="dropdown-menu-arrow" right>
               <DropdownItem
              href="#pablo"
              
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber,status)}
            >
              View
            </DropdownItem>
            
           </DropdownMenu>
             
              }
             
          </UncontrolledDropdown>
        </p>
      </div>

        
         <p className="ml-4 mr-10 phoneviewcontent mt-2">{quotationnumber}</p>     
     
        <p className="ml-4 mr-10 phoneviewcontent">
          <Badge color="" className="badge-dot mr-4  mt-2">
            {status =="Pending"                
            ?
            <i className="bg-info" />
            :
            <i className="bg-warning" />
          }
          
            {status}
          </Badge>
        </p>
        <p className="ml-4 mr-10 phoneviewcontent  mt-2">
         
          <a
              className="avatar avatar-sm"
              href="#pablo" 
                   
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle"
                src={imgurllastedit}
                id={"tooltipending"+no}
              />
            </a>             
              
       
        </p>
      </div>
      
      )
    })
}

  expandbuttonclick = (no) =>{
     this.setState({["expand" + 1]:1})
     //this.divclicked
  }

  //  divclicked = () => {
  //   if (this.state.myclass === '') {
  //    this.setState({
  //     myclass: 'coolclass'
  //    })
  //   }
  //  else {
  //   this.setState({
  //     myclass: '',
  //   })
  //  }
  // }

 renderTableFinalData() {
      
    if(this.state.currentquotationpagenumber ==1){
      return this.state.quotationtabledatafinal.slice(0,100).map((data, index) => {
        const { no, shippernametoshow,shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
        const imgurllastedit = this.state.imageurllink + profileimg
        console.log("shippernametoshow: " + shippernametoshow)
        //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
      // // console.log(imgurllastedit)
        return (
          
            <tbody>
                          
                    <tr>
                    <td >{no})</td>
                    <th scope="row">     
                            {shippernametoshow}         
                    </th>
                    <td >{quotationnumber}</td>
                    
                    <td >
                      <div className="avatar-group d-md-inline-block colforlastedit">
                      <a
                          className="avatar avatar-sm"
                          href="#pablo"
                          id={"tooltip" + no}
                          onClick={e => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={imgurllastedit}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={"tooltip" + no}
                        >
                          {lasteditname}
                        </UncontrolledTooltip>
                        <div className="mt--2 text-sm d-md-inline-block">
                              {Moment(lastview).format('DD/MM/YYYY')}
                            </div>
                      </div>
                    </td>                     
                    <td >
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                        >
                          <i className="fas fa-ellipsis-v" />
                     
                        </DropdownToggle>
                        
                         
                      </UncontrolledDropdown>
                    </td>
                  
                  </tr>
                  </tbody>
              
      
        )
     })
         
    }else if(this.state.currentquotationpagenumber == 2){
          return this.state.quotationtabledatafinal.slice(100,200).map((data, index) => {
            const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
            const imgurllastedit = this.state.imageurllink + profileimg
            //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
           // console.log(imgurllastedit)
            return (
            
            <tbody>
                          
                    <tr>
                    <td >{no})</td>
                    <th scope="row">     
                            {shippernametoshow}         
                    </th>
                    <td>{quotationnumber}</td>
                    
                    <td >
                      <div className="avatar-group d-md-inline-block colforlastedit">
                      <a
                          className="avatar avatar-sm"
                          href="#pablo"
                          id={"tooltip" + no}
                          onClick={e => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            src={imgurllastedit}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={"tooltip" + no}
                        >
                          {lasteditname}
                        </UncontrolledTooltip>
                        <div className="mt--2 text-sm d-md-inline-block">
                              {Moment(lastview).format('DD/MM/YYYY')}
                            </div>
                      </div>
                    </td>                     
                    <td >
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                        >
                          <i className="fas fa-ellipsis-v" />
                     
                        </DropdownToggle>
                        
                         
                      </UncontrolledDropdown>
                    </td>
                  
                  </tr>
                  </tbody>
              
            
            )
         })
        }else if(this.state.currentquotationpagenumber == 3){
          return this.state.quotationtabledatafinal.slice(200,300).map((data, index) => {
            const {shippernametoshow, no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
            const imgurllastedit = this.state.imageurllink + profileimg
            //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
           // console.log(imgurllastedit)
            return (
            
              <tbody>
                            
                      <tr>
                      <td >{no})</td>
                      <th scope="row">     
                              {shippernametoshow}         
                      </th>
                      <td>{quotationnumber}</td>
                      
                      <td >
                        <div className="avatar-group d-md-inline-block colforlastedit">
                        <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id={"tooltip" + no}
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={imgurllastedit}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip" + no}
                          >
                            {lasteditname}
                          </UncontrolledTooltip>
                          <div className="mt--2 text-sm d-md-inline-block">
                                {Moment(lastview).format('DD/MM/YYYY')}
                              </div>
                        </div>
                      </td>                     
                      <td >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                          >
                            <i className="fas fa-ellipsis-v" />
                       
                          </DropdownToggle>
                          
                           
                        </UncontrolledDropdown>
                      </td>
                    
                    </tr>
                    </tbody>
                
            )
         })
        }else if(this.state.currentquotationpagenumber == 4){
          return this.state.quotationtabledatafinal.slice(300,400).map((data, index) => {
            const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
            const imgurllastedit = this.state.imageurllink + profileimg
            //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
           // console.log(imgurllastedit)
            return (
             
              <tbody>
                            
                      <tr>
                      <td >{no})</td>
                      <th scope="row">     
                              {shippernametoshow}         
                      </th>
                      <td >{quotationnumber}</td>
                      
                      <td >
                        <div className="avatar-group d-md-inline-block colforlastedit">
                        <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id={"tooltip" + no}
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={imgurllastedit}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip" + no}
                          >
                            {lasteditname}
                          </UncontrolledTooltip>
                          <div className="mt-2 text-sm d-md-inline-block">
                                {Moment(lastview).format('DD/MM/YYYY')}
                              </div>
                        </div>
                      </td>                     
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                          >
                            <i className="fas fa-ellipsis-v" />
                       
                          </DropdownToggle>
                          
                           
                        </UncontrolledDropdown>
                      </td>
                    
                    </tr>
                    </tbody>
                
    
            )
         })
        }else if(this.state.currentquotationpagenumber == 5){
          return this.state.quotationtabledatafinal.slice(400,500).map((data, index) => {
            const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
            const imgurllastedit = this.state.imageurllink + profileimg
            //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
           // console.log(imgurllastedit)
            return (
              
              <tbody>
                            
                      <tr>
                      <td >{no})</td>
                      <th scope="row">     
                              {shippernametoshow}         
                      </th>
                      <td >{quotationnumber}</td>
                      
                      <td >
                        <div className="avatar-group d-md-inline-block colforlastedit">
                        <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id={"tooltip" + no}
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={imgurllastedit}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip" + no}
                          >
                            {lasteditname}
                          </UncontrolledTooltip>
                          <div className="mt-2 text-sm d-md-inline-block position-absolute">
                                {Moment(lastview).format('DD/MM/YYYY')}
                              </div>
                        </div>
                      </td>                     
                      <td >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                          >
                            <i className="fas fa-ellipsis-v" />
                       
                          </DropdownToggle>
                          
                           
                        </UncontrolledDropdown>
                      </td>
                    
                    </tr>
                    </tbody>
                
       
            
            )
         })
        }else if(this.state.currentquotationpagenumber == 6){
          return this.state.quotationtabledatafinal.slice(500,this.state.quotationlistcounttotal).map((data, index) => {
            const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
            const imgurllastedit = this.state.imageurllink + profileimg
            //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
           // console.log(imgurllastedit)
            return (
              
              <tbody>
                            
                      <tr>
                      <td >{no})</td>
                      <th scope="row">     
                              {shippernametoshow}         
                      </th>
                      <td >{quotationnumber}</td>
                      
                      <td >
                        <div className="avatar-group d-md-inline-block colforlastedit">
                        <a
                            className="avatar avatar-sm"
                            href="#pablo"
                            id={"tooltip" + no}
                            onClick={e => e.preventDefault()}
                          >
                            <img
                              alt="..."
                              className="rounded-circle"
                              src={imgurllastedit}
                            />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip" + no}
                          >
                            {lasteditname}
                          </UncontrolledTooltip>
                          <div className="mt-2 text-sm d-md-inline-block position-absolute">
                                {Moment(lastview).format('DD/MM/YYYY')}
                              </div>
                        </div>
                      </td>                     
                      <td >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
                          >
                            <i className="fas fa-ellipsis-v" />
                       
                          </DropdownToggle>
                          
                           
                        </UncontrolledDropdown>
                      </td>
                    
                    </tr>
              </tbody>
                
        
            
            )
         })
        }   
     
    
 } 

 renderTableFinalDataphone() {
      
  if(this.state.currentquotationpagenumber ==1){
    return this.state.quotationtabledatafinal.slice(0,100).map((data, index) => {
      const { no, shippernametoshow,shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
      const imgurllastedit = this.state.imageurllink + profileimg
      console.log("shippernametoshow: " + shippernametoshow)
      //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
    // // console.log(imgurllastedit)
      return (        
               
                  
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
      )
   })
       
  }else if(this.state.currentquotationpagenumber == 2){
        return this.state.quotationtabledatafinal.slice(100,200).map((data, index) => {
          const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
          const imgurllastedit = this.state.imageurllink + profileimg
          //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         // console.log(imgurllastedit)
          return (
                  
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
    
          )
       })
      }else if(this.state.currentquotationpagenumber == 3){
        return this.state.quotationtabledatafinal.slice(200,300).map((data, index) => {
          const {shippernametoshow, no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
          const imgurllastedit = this.state.imageurllink + profileimg
          //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         // console.log(imgurllastedit)
          return (
                 
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
          )
       })
      }else if(this.state.currentquotationpagenumber == 4){
        return this.state.quotationtabledatafinal.slice(300,400).map((data, index) => {
          const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
          const imgurllastedit = this.state.imageurllink + profileimg
          //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         // console.log(imgurllastedit)
          return (
                  
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
  
          )
       })
      }else if(this.state.currentquotationpagenumber == 5){
        return this.state.quotationtabledatafinal.slice(400,500).map((data, index) => {
          const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
          const imgurllastedit = this.state.imageurllink + profileimg
          //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         // console.log(imgurllastedit)
          return (
                   
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
     
          
          )
       })
      }else if(this.state.currentquotationpagenumber == 6){
        return this.state.quotationtabledatafinal.slice(500,this.state.quotationlistcounttotal).map((data, index) => {
          const { shippernametoshow,no, shippername, quotationnumber, lastedit ,shipperimage,lastview,lasteditname,profileimg} = data //destructuring
          const imgurllastedit = this.state.imageurllink + profileimg
          //const shipperlogourl = "https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/img/shipper_img/" + shipperimage
         // console.log(imgurllastedit)
          return (
                   
             
        <div className="mt-1 cardviewbox">
        <div className="horizontaldirection">
            <h4 className="ml-4 mt-10 mb-0 ">     
                    {shippernametoshow}         
            </h4>
            <p className="ml-auto phoneviewcontent">
          <UncontrolledDropdown>
            <DropdownToggle
              className="btn-icon-only text-light"
              href="#pablo"
              role="button"
              size="sm"
              color="black"
              onClick={() => this.toggleModal("exampleModal",shippername,quotationnumber)}
            >
              <i className="fas fa-ellipsis-v" />
         
            </DropdownToggle>
            
             
          </UncontrolledDropdown>
        </p>
      
          </div>
   
        < p className="ml-4 mr-10 mt--1 phoneviewcontent" >{quotationnumber}</p>
        
        
        <p className="ml-4 mr-10 phoneviewcontent">
         
          <a
              className="avatar avatar-sm   mr-10 phoneviewcontent"
              href="#pablo"
              id={"tooltip" + no}
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                className="rounded-circle  "
                src={imgurllastedit}
              />
            </a>
          
        </p>                     
       
        
</div>

    
      
          
          )
       })
      }   
   
  
} 

 nextpaginationfunction = (e) =>{
   if(this.state.quotationlistcounttotal/100 <= this.state.currentquotationpagenumber){

   }else{
    this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})
   }
 }

 previouspaginationfunction = (e) =>{
  if(this.state.currentquotationpagenumber== 1){
    
  }else{
   this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber - 1})
  }
}
 renderTablePagination() {
        
     { if( this.state.quotationlistcounttotal/100 < 1){
       return( null )
     }else if(this.state.quotationlistcounttotal/100 >= 1 && this.state.quotationlistcounttotal/100 < 2 ){
      return (
                <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="justify-content-end"
                >
                    <PaginationItem   >
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => this.previouspaginationfunction()}
                        tabIndex="-1"
                      >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                        1
                      </PaginationLink>
                    </PaginationItem>               
                          <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                           <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                             2
                           </PaginationLink>
                         </PaginationItem>      
                <PaginationItem>
                        <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                          <i className="fa fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>                                    
              )
     }else if(this.state.quotationlistcounttotal/100 >= 2 && this.state.quotationlistcounttotal/100 < 3 ){
      return (
                <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="justify-content-end"
                >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                        tabIndex="-1"
                      >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                        1
                      </PaginationLink>
                    </PaginationItem>               
                    <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                             2
                           </PaginationLink>
                         </PaginationItem>    
                         <PaginationItem className={this.state.currentquotationpagenumber == 3? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                             3
                           </PaginationLink>
                         </PaginationItem>      
                <PaginationItem>
                        <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                          <i className="fa fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>                                    
              )
     }else if(this.state.quotationlistcounttotal/100 >= 3 && this.state.quotationlistcounttotal/100 < 4 ){
      return (
                <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="justify-content-end"
                >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber -1})}
                        tabIndex="-1"
                      >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                        1
                      </PaginationLink>
                    </PaginationItem>               
                    <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                             2
                           </PaginationLink>
                         </PaginationItem>    
                         <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                             3
                           </PaginationLink>
                         </PaginationItem>  
                         <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                             4
                           </PaginationLink>
                         </PaginationItem>          
                <PaginationItem>
                        <PaginationLink href="#pablo" onClick={(e) => this.setState({currentquotationpagenumber:this.state.currentquotationpagenumber +1})}>
                          <i className="fa fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>                                    
              )
     }else if(this.state.quotationlistcounttotal/100 >= 4 && this.state.quotationlistcounttotal/100 < 5 ){
      return (
                <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="justify-content-end"
                >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                        1
                      </PaginationLink>
                    </PaginationItem>               
                    <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                             2
                           </PaginationLink>
                         </PaginationItem>    
                         <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                             3
                           </PaginationLink>
                         </PaginationItem>  
                         <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                             4
                           </PaginationLink>
                         </PaginationItem>   
                         <PaginationItem className={this.state.currentquotationpagenumber == 5 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:5})}>
                             5
                           </PaginationLink>
                         </PaginationItem>           
                <PaginationItem>
                        <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fa fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>                                    
              )
     } else if(this.state.quotationlistcounttotal/100 >= 5 && this.state.quotationlistcounttotal/100 < 6 ){
      return (
                <nav aria-label="Page navigation example">
                <Pagination
                  className="pagination justify-content-end"
                  listClassName="justify-content-end"
                >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        tabIndex="-1"
                      >
                        <i className="fa fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={this.state.currentquotationpagenumber == 1 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:1})}>
                        1
                      </PaginationLink>
                    </PaginationItem>               
                    <PaginationItem className={this.state.currentquotationpagenumber == 2 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:2})}>
                             2
                           </PaginationLink>
                         </PaginationItem>    
                         <PaginationItem className={this.state.currentquotationpagenumber == 3 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:3})}>
                             3
                           </PaginationLink>
                         </PaginationItem>  
                         <PaginationItem className={this.state.currentquotationpagenumber == 4 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:4})}>
                             4
                           </PaginationLink>
                         </PaginationItem>   
                         <PaginationItem className={this.state.currentquotationpagenumber == 5 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:5})}>
                             5
                           </PaginationLink>
                         </PaginationItem>  
                         <PaginationItem className={this.state.currentquotationpagenumber == 6 ? "active" : ""}>
                      <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentquotationpagenumber:6})}>
                             ...
                           </PaginationLink>
                         </PaginationItem>           
                <PaginationItem>
                        <PaginationLink href="#pablo" onClick={e => e.preventDefault()}>
                          <i className="fa fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>                                    
              )
     } 
     
     console.log("quotationcount: " + this.state.quotationlistcounttotal)
    }
 }

 cancelledsearchbuttonclick = () =>{
  this.setState({searchtextvalue:""})
  this.setState({pagenumber:1})
  this.getfilemanager();
  
 }
 
 searchfunctionstartquotationlist = () =>{
  //  if(this.state.searchtextvalue.length < 3){
  //   window.confirm('Please insert more than 3 word in Search box Quotation')
  //  }else{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({currentquotationpagenumber:1})
  
    this.setState({loading:true})
    this.setState({tabs:2})
    this.setState({loading:true})
    this.setState({quotationtabledatafinal:[]})
    var urlencoded = new URLSearchParams();
    urlencoded.append("searchvalue",this.state.searchtextvalue);
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getquotationsearchlist'
   
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
  
          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  shippernametoshow:json.user[i].Shipper.slice(0,30),
                  quotationnumber:json.user[i].Quotationnumber,
                  lastedit:json.user[i].LastEdit,
                  lasteditname:'',
                  lastview:(json.user[i].Lastview-28800)*1000,
                },            
              ]})
              
              
             
             //  console.log(this.state.quotationtabledatafinal)
             
  
             
              }
              this.setState({quotationlistcounttotal: Object.keys(this.state.quotationtabledatafinal).length});
              console.log(this.state.quotationlistcounttotal)
              this.state.quotationtabledatafinal.sort((a, b) => (b.shippername - a.shippername))
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
      this.getlasteditbywho()
     })
  // }
 
 
  
  }

 searchfunctionstartquotationpending = () =>{
    // if(this.state.searchtextvalue.length < 3){
    //  window.confirm('Please insert more than 3 word in Search box Quotation')
    // }else{
      this.setState({quotationtabledata:[]})
      this.setState({buttoncolor1:"primary"})
     this.setState({buttoncolor2:"white"})
 
     this.setState({loading:true})
     
     this.setState({tabs:1})

     var urlencoded = new URLSearchParams();
     urlencoded.append("searchvalue",this.state.searchtextvalue);
     urlencoded.append("billtoparty", this.state.Billtoparty);
     let link = this.state.linkurl +'mode=admin-getquotationpendingsearchlist'
    
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       if(json.success == 1){
         
            
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){
 
             
                 var statusword = 'Pending'
               
                 this.setState({quotationtabledata:[...this.state.quotationtabledata,
                   {no:i+1,
                    shippername:json.user[i].Shipper,
                    shippernametoshow:json.user[i].Shipper.slice(0,30),
                    quotationnumber:json.user[i].Quotationnumber,
                    status:statusword,
                    lastedit:json.user[i].LastEdit,
                    lasteditname:'1',
                    lastview:(json.user[i].Lastview-28800)*1000,
                   },            
                 ]})
                        
               
               
              
               console.log(this.state.quotationtabledata)
              
 
              
               }
               



             }        
                    
          else{
           this.setState({loading:false}) 
         console.log(json)
       }

     
      })
 

      this.searchfunctioncancelpending();
      
   // }
  }

searchfunctioncancelpending = ()=>{
  this.setState({buttoncolor1:"primary"})
  this.setState({buttoncolor2:"white"})

  this.setState({loading:true})
 // this.setState({quotationtabledata:[]})
 // this.setState({tabs:1})

  var urlencoded = new URLSearchParams();
  urlencoded.append("searchvalue",this.state.searchtextvalue);
  urlencoded.append("billtoparty", this.state.Billtoparty);

  let link = this.state.linkurl +'mode=admin-getquotationcancelledsearchlist'
 fetch(link, {
   method: 'POST', 
   headers: {
     'Accept': 'application/json',
     'Content-Type': 'application/x-www-form-urlencoded',
   },
   body:urlencoded
 })
 .then(response => response.json())
 .then(json => {
   console.log(json.success)
   if(json.success == 1){
     
         var lengthemployee = Object.keys(json.user).length;
         for(let i=0;i<lengthemployee;i++){

           var statusword = 'Cancelled'
         
           this.setState({quotationtabledata:[...this.state.quotationtabledata,
             {no:this.state.quotationtabledata.length + i+1,
               shippername:json.user[i].Shipper,
               quotationnumber:json.user[i].Quotationnumber,
               status:statusword,
               lastedit:json.user[i].LastEdit,
               lasteditname:'',
               lastview:(json.user[i].Lastview-28800)*1000,
             },            
           ]})
           
           
          
           console.log(this.state.quotationtabledata)
          

          
           }
           //sort data here
          // this.state.quotationtabledata.sort((a, b) => (b.shippername - a.shippername))
           this.setState({loading:false}) 
         }        
                
      else{
       this.setState({loading:false}) 
     console.log(json)
   }
   this.getlasteditbywho()
  })

}

 
searchfunctionstart=()=>{
  if(this.state.tabs == 1){
    this.searchfunctionstartquotationpending()
  }else if(this.state.tabs == 2){
    this.searchfunctionstartquotationlist()
  }
}

renderTablePagination() {
         
  { if( this.state.completelist.length/100 < 1){
    return( null )
  }else if(this.state.completelist.length/100 >= 1 && this.state.completelist.length/100 < 2 ){
   return (
     
        
      <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                       <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                        <PaginationLink href="#pablo" onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>      
             <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
              
             )
  }else if(this.state.completelist.length/100 >= 2 && this.state.completelist.length/100 < 3 ){
   return (
      
           
         <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>      
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           
         
        


             )
  }else if(this.state.completelist.length/100 >= 3 && this.state.completelist.length/100 < 4 ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                   <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber ==2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>          
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  }else if(this.state.completelist.length/100 >= 4 && this.state.completelist.length/100 < 5 ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                 <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>   
                      <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                          5
                        </PaginationLink>
                      </PaginationItem>           
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  }else if(this.state.completelist.length/100 >= 5  ){
   return (
             <nav aria-label="Page navigation example">
             <Pagination
               className="pagination justify-content-end"
               listClassName="justify-content-end"
             >
                <PaginationItem   >
                   <PaginationLink
                     href="#pablo"
                     onClick={(e) => this.previouspaginationfunction()}
                     tabIndex="-1"
                   >
                     <i className="fa fa-angle-left" />
                     <span className="sr-only">Previous</span>
                   </PaginationLink>
                 </PaginationItem>
                 <PaginationItem className={this.state.currentcompletedpagenumber == 1 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:1})}>
                     1
                   </PaginationLink>
                 </PaginationItem>               
                 <PaginationItem className={this.state.currentcompletedpagenumber == 2 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:2})}>
                          2
                        </PaginationLink>
                      </PaginationItem>    
                      <PaginationItem className={this.state.currentcompletedpagenumber == 3 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:3})}>
                          3
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 4 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:4})}>
                          4
                        </PaginationLink>
                      </PaginationItem>   
                      <PaginationItem className={this.state.currentcompletedpagenumber == 5 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:5})}>
                          5
                        </PaginationLink>
                      </PaginationItem>  
                      <PaginationItem className={this.state.currentcompletedpagenumber == 6 ? "active" : ""}>
                   <PaginationLink href="#pablo"  onClick={(e) => this.setState({currentcompletedpagenumber:6})}>
                          ...
                        </PaginationLink>
                      </PaginationItem>           
                      <PaginationItem>
                     <PaginationLink href="#pablo" onClick={(e) => this.nextpaginationfunction()}>
                       <i className="fa fa-angle-right" />
                       <span className="sr-only">Next</span>
                     </PaginationLink>
                   </PaginationItem>
                 </Pagination>
               </nav>                                    
           )
  } 
  
 }
}

  
 

  render() {
    Moment.locale('en');
    var dt = this.state.date;
   return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--6" fluid >
          {/* Table */}
         
              <Card className=" shadow p-10 mt-10">

                <Row>
                  <Row className="marginleftcss mt-2">                
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">From: </span>
                    </Col>
                    <Col md={3}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">To: </span>
                    </Col>
                    <Col md={3}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                   
                   
                    <Col md={2}>
                    <Button  className={classnames("mb-sm-3 mb-md-0")}  color="primary" type="button" style={{marginLeft:-10}} onClick={(e)=>{ this.setState({pagenumber:1});this.getfilemanager()}}>
                         Search
                      </Button>
                    </Col>
                    
                    <Col md="3">
                   {this.renderTablePagination()}
                 </Col>
                 </Row>
           


              {window.innerWidth <= 760 ?
                <InputGroup className="col-11 mt-2 form-inline alignmiddletbutton d-md-flex ml-lg-auto mb--3 input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => {this.setState({pagenumber:1});this.setState({searchtextvalue:event.target.value});this.getfilemanager(event.target.value,1)}}
                      type="text"
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                  
                <span className="buttonsearch" onClick={(e)=>{this.setState({pagenumber:1});this.setState({searchtextvalue:e.target.value});this.getfilemanager(e.target.value,1)}}>Search</span >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }                  
              
                </InputGroup>
              :
<InputGroup className="col-4 mt-2 form-inline mr-3 d-none d-md-flex ml-lg-auto mb-0 input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                   
                      <i className="fas fa-search" />
                     
                    </InputGroupText>
                  </InputGroupAddon>
                    <Input
                      className="mt-2"
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => {this.setState({pagenumber:1});this.setState({searchtextvalue:event.target.value});this.getfilemanager(event.target.value,1)}}
                      type="text"
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                  
                 <span className="buttonsearch" onClick={(e)=>{this.setState({pagenumber:1});this.setState({searchtextvalue:e.target.value});this.getfilemanager(e.target.value,1)}}>Search</span >
                   
                 </InputGroupText>
               </InputGroupAddon>
                 }
                    
                     
                  
                </InputGroup>
                }
</Row>
              <CardBody>
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
           
              :
               <TabContent activeTab={"tabs" + this.state.tabs}>
               <TabPane tabId="tabs2">
                
                 <Table className="align-items-center" responsive>
                 <thead className="thead-light">
                   <tr>
                     <th scope="col">No.</th>
                     <th scope="col">Consignment No</th>
                     <th scope="col">Booking No</th>      
                     <th scope="col">Date</th>                  
                     <th scope="col flex-md-row ">
                      <Row > 
                        
                        <div style={{marginTop:14,marginRight:10}} >
                        {/* <Checkbox                                   
                        value={this.state.checkboxvalue}
                        onChange = {(event) => this.setState({ checkboxvalue: event.target.value })}
                      ></Checkbox> */}
                        </div>
                      <h5 style={{marginTop:12}}>Files/Images</h5>
                     
                     
                      
                    
                      {/* <Button style={{marginLeft:10,backgroundColor:"#b1b5b5"}}>
                      <i className="ni ni-cloud-download-95 mr-2" />
                        Download All
                      </Button>
                     */}
                     </Row>
                      </th>
                      
                     <th scope="col">Action</th> 
                   </tr>
                 </thead>
                 <tbody>
                 {this.renderfilemanager()}
                 </tbody>
               </Table>
               

                 
               </TabPane>
            
             </TabContent>}
             {this.state.pagenumber == 1 ?
             null
             :
             <Button  className={classnames("mb-sm-3 mb-md-0")}  color="primary" type="button" style={{marginLeft:10}} onClick={(e)=>{this.previousbuttonclick()}}>
             Previous
          </Button>
             }
           
             <Button  className={classnames("mb-sm-3 mb-md-0")}  color="primary" type="button" style={{marginLeft:10}} onClick={(e)=>{this.nextbuttonclick()}}>
                         Next
                      </Button>
          </CardBody>
                
              </Card>
             
                


        </Container>
     
       

        {/* View with edit button pop out*/}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.exampleModal2}
          toggle={() => this.toggleModal("exampleModal2")}
        >
         
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              View
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal2")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
           
          <div className="modal-body">
          {this.state.loadingmodal
              ?
              <Row className="align-items-center" responsive>
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
              </Row>
           
              :
          <Form>
          <Row>
            <Col md="12">
              <p className="h5">Bill To Party:</p>
              <FormGroup>
                <Input
                  disabled 
                  id="Billtopartyinput1"
                  placeholder={this.state.detailbilltoparty}
                  type="text"
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Shipper/Consignee:</p>
              <FormGroup>
              <Input
                  disabled 
                  id="Shipperinput1"
                  placeholder={this.state.detailshipper}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Address:</p>
              <FormGroup>
              <Input
                  disabled 
                  id="Addressinput1"
                  placeholder={this.state.detailaddress}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Area:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Areainput1"
                  placeholder={this.state.detailarea}
                  type="text"
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">PIC:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Picinput1"
                  placeholder={this.state.detailpic}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Depo Gate Charge:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Depogatechargeinput1"
                  placeholder={this.state.detaildepo}
                  type="text"
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Contact:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Contactinput1"
                  placeholder={this.state.detailcontact}
                  type="text"
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Sideloader Charge:</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Backtobackchargeinput1"
                  placeholder={this.state.detailsideloadercharge}
                  type="text"
                  onChange={e => this.handleChange(e.target.value,"detailsideloadercharge")} 
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Back to Back Charge(2 x 20'):</p>
              <FormGroup>
              <Input
                 disabled 
                  id="Backtobackchargeinput1"
                  placeholder="Back to Back Charge"
                  type="text"
                />
              </FormGroup>
              </Col>
         
          </Row>
          <Row>
          <Col md="6">
            


          <p className="h5">PGU</p>
          <Table className="align-items-center" responsive>
                        
                        <thead className="thead-light-blue">
                          <tr>
                          <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.pguprice20gp}</td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td>{this.state.pguprice20rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td>{this.state.pguprice20isotank}</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td>{this.state.pguprice20ot}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td>{this.state.pguprice20tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.pguprice40gp}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td>{this.state.pguprice40rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td>{this.state.pguprice40isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td>{this.state.pguprice40tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>OT</td>
                            <td>{this.state.pguprice40ot}</td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
          </Col>
          <Col md="6">
          
          <p className="h5">PTP</p>   
          <Table className="align-items-center" responsive>
                        <thead className="thead-light-blue">
                          <tr>
                            <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.ptpprice20gp}</td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td>{this.state.ptpprice20rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td>{this.state.ptpprice20isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td>{this.state.ptpprice20ot}</td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td>{this.state.ptpprice20tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td>{this.state.ptpprice40gp}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td>{this.state.ptpprice40rf}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td>{this.state.ptpprice40isotank}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td>{this.state.ptpprice40tipper}</td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>OT</td>
                            <td>{this.state.ptpprice40ot}</td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
               
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal2")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => { if (window.confirm('Are you sure you wish to edit this Quotation?')) this.toggleModal("exampleModal2");this.toggleModal("editpopuout");this.setState({loadingmodal:false})} } >
              Edit
            </Button>
          </div>
      
              </Col>
         
          </Row>
        </Form>
            }
          </div>
       
       </Modal>

        {/* Edit Pop Out */}
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.editpopuout}
          toggle={() => this.toggleModal("editpopuout")}
        >
          
          <div className="modal-header">
            <h5 className="modal-title" id="editpopuoutLabel">
              Edit
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("editpopuout")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>

       
          <div className="modal-body p-10">
            
          {
            //loading when 3
          this.state.aftersubmitupdatestatus == 3
              ?
          <Row className="align-items-center" responsive>
                    <Col md="12" >
              <img 
              className="img-center"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />  
              </Col>
              </Row>
              :
          this.state.aftersubmitupdatestatus == 2 ? 
          // success submit when 2
          <CardBody>
          <div className="modal-header">
      </div>
      <div className="modalforquotationaddnew">
      <Form>
        <Row>
        <Col md="12">
            <img 
            className="img-quotationlogodonenew"
            width="120"
            height="120"
            alt="..."
            src={require("./../../assets/img/brand/logomewah4.png")}
          />
          <p className="word-quotationaddnewfinish" >Thanks for choosing Mewahtrans Logistics,</p>
          <p className="word-quotationaddnewfinish">Your Quotation Number had updated.</p>
         
          <Button
              
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closebuttonfunction()}
            >
              Close
            </Button>
   
        </Col>
        
      </Row>
      </Form>
     </div>
       
      </CardBody>
          :
          //fail submit when 4
          this.state.aftersubmitupdatestatus == 4 
          ?
          <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Update fail,Something wrong occur, please try again later. </p>
              
             
              <Button
              
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closebuttonfunction()}
            >
              Close
            </Button>
        
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
          :
          //normal when 0
          <Form>
          <Row>
            <Col md="12">
              <p className="h5">Bill To Party:</p>
              <FormGroup>
                <Input
                  id="Billtopartyinput1"
                  placeholder="Bill To Party"
                  value={this.state.detailbilltoparty}
                  type="text"
                  disabled={true}
                  onChange={e => this.handleChange(e.target.value,"detailbilltoparty")} 
                />
              </FormGroup>
            </Col>
            
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Shipper/Consignee:</p>
              <FormGroup>
              <Input
                  id="Shipperinput1"
                  placeholder="Shipper/Consignee"
                  type="text"
                  value={this.state.detailshipper}
                  onChange={e => this.handleChange(e.target.value,"detailshipper")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="12">
              <p className="h5">Address:</p>
              <FormGroup>
              <Input
                  id="Addressinput1"
                  placeholder="Address"
                  type="text"
                  value={this.state.detailaddress}
                  onChange={e => this.handleChange(e.target.value,"detailaddress")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Area:</p>
              <FormGroup>
              <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.arealist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({detailarea:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.detailarea} variant="outlined"   />}
                        disableClearable={true}
                      />
              {/* <Input
                  id="Areainput1"
                  placeholder="Area"
                  type="text"
                  value={this.state.detailarea}
                  onChange={e => this.handleChange(e.target.value,"detailarea")} 
                /> */}
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">PIC:</p>
              <FormGroup>
              <Input
                  id="Picinput1"
                  placeholder="PIC"
                  type="text"
                  value={this.state.detailpic}
                  onChange={e => this.handleChange(e.target.value,"detailpic")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Depo Gate Charge:</p>
              <FormGroup>
              <Input
                  id="Depogatechargeinput1"
                  placeholder="Depo Gate Charge"
                  value={this.state.detaildepo}
                  type="text"
                  onChange={e => this.handleChange(e.target.value,"detaildepo")} 
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Contact:</p>
              <FormGroup>
              <Input
                  id="Contactinput1"
                  placeholder="Contact"
                  type="text"
                  value={this.state.detailcontact}
                  onChange={e => this.handleChange(e.target.value,"detailcontact")} 
                />
              </FormGroup>
            </Col>
           
          </Row>
          <Row>
          <Col md="6">
              <p className="h5">Sideloader Charge:</p>
              <FormGroup>
              <Input
                  id="Backtobackchargeinput1"
                  placeholder={this.state.detailsideloadercharge}
                  type="text"
                  onChange={e => this.handleChange(e.target.value,"detailsideloadercharge")} 
                />
              </FormGroup>
              </Col>
          <Col md="6">
              <p className="h5">Back to Back Charge(2 x 20'):</p>
              <FormGroup>
              <Input
                  id="Backtobackchargeinput1"
                  placeholder="Back to Back Charge"
                  type="text"
               
                />
              </FormGroup>
              </Col>
         
          </Row>
          <Row>
          <Col md="6">
          <p className="h5">PGU</p>
          <Table className="align-items-center" responsive>
            
                        <thead className="thead-light-blue">
                          <tr>
                          <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td>
                              <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  value={this.state.pguprice20gp}
                  type="number"
                  min="0"
                  onChange={e => this.handleChange(e.target.value,"pguprice20gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20rf}
                  onChange={e => this.handleChange(e.target.value,"pguprice20rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20isotank}
                  onChange={e => this.handleChange(e.target.value,"pguprice20isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20ot}
                  onChange={e => this.handleChange(e.target.value,"pguprice20ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice20tipper}
                  onChange={e => this.handleChange(e.target.value,"pguprice20tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40gp}
                  onChange={e => this.handleChange(e.target.value,"pguprice40gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40rf}
                  onChange={e => this.handleChange(e.target.value,"pguprice40rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40isotank}
                  onChange={e => this.handleChange(e.target.value,"pguprice40isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40tipper}
                  onChange={e => this.handleChange(e.target.value,"pguprice40tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>

                          <tr>
                            <td>40</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.pguprice40ot}
                  onChange={e => this.handleChange(e.target.value,"pguprice40ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
          </Col>
          <Col md="6">
          <p className="h5">PTP</p>
          <Table className="align-items-center" responsive>
                        <thead className="thead-light-blue">
                          <tr>
                            <th scope="col">Size</th>
                            <th scope="col">Type</th>
                            <th scope="col">Price</th>    
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20gp}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          
                          <tr>
                            <td>20</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20rf}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20isotank}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20ot}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice20tipper}
                  onChange={e => this.handleChange(e.target.value,"ptpprice20tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>GP/UG/HC</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40gp}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40gp")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>RF</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40rf}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40rf")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>ISOTANK</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40isotank}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40isotank")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>Tipper</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40tipper}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40tipper")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                          <tr>
                            <td>40</td>
                            <td>OT</td>
                            <td> <FormGroup>
              <Input
                  id="gp20pgu"
                  placeholder="50"
                  type="number"
                  min="0"
                  value={this.state.ptpprice40ot}
                  onChange={e => this.handleChange(e.target.value,"ptpprice40ot")} 
                />
              </FormGroup></td>  
                             
                          </tr>
                        </tbody>
                      </Table>
                      
            </Col>
           
          </Row>
          <Row>
          <Col md="12" >
          <div className="modal-footer">
          <Button
              
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("editpopuout")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={(e) => {if (window.confirm('Are you sure you wish to edit this Quotation?'))  this.saveandchangeeditquotationpending()}}>
              Save changes
            </Button>
            </div>
              </Col>
            
          </Row>
        </Form>
          }
          
          </div>
        
            
        </Modal>
      
       {/* popout ask for email */}
       <Modal
          className="modal-dialog-centered modal-primary"
          contentClassName="bg-gradient-primary"
          size="lg"
          isOpen={this.state.popoutemail}
          toggle={() => this.setState({popoutemail:false})}
        >
          {this.state.loadingmodal?
           <Row >
           <Col md="12" >
         <img 
         className="img-center"
         width="120"
         height="120"
         alt="..."
         src={require("./../../assets/gif/lorrygif3.gif")}
       />
       </Col>
         </Row>:
          <div>
          <div className="modal-header">
         
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({popoutemail:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body p-10 ">
        <Form>
        <Row>
          <Col md="12">
            <div className="alignmiddletbuttonemailpopout">
            <i className="ni ni-email-83 ni-3x " />
            </div>
         
          <h3 className="modal-title alignmiddletbuttonemailpopout" >
            Please contact Mewahtrans Admin to edit or cancel this quotation.
          </h3>
          <h2 className="modal-title alignmiddletbuttonemailpopout mt-1" >
           Email: operation@mewahtrans.com.my
          </h2>
          </Col>
         
        </Row>
        </Form>
        
       </div>
       <div className="modal-footer d-md-inline-block alignmiddletbuttonemailpopout col-12">
            <Button
              color="secondary"
              data-dismiss="modal"
              className="d-md-inline-block alignmiddletbuttonemailpopout"
              type="button"
              onClick={() => window.open("mailto:operation@mewahtrans.com.my?cc=fernyong@mewahtrans.com.my&subject=Edit%20or%20Cancel%20Quotation%20request", "_blank") }
            >
              Go to Email
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              className="d-md-inline-block alignmiddletbuttonemailpopout"
              type="button"
              onClick={() => this.setState({popoutemail:false})}
            >
              Close
            </Button>
          </div>
          
         
          </div>}
          </Modal>  


      </>
    );
    }
    
 
  }
  class ComponentToPrint extends React.Component {
  
    state= {  
      
      printbilltoparty:this.props.billingto,
      printbookingno:this.props.Bookingno,
      printclosing:this.props.closingdate,
      printcommodity:this.props.commodity,
      printcontainer1:this.props.container1,
      printcontainer2:this.props.container2,
      printcontainer3:this.props.container3,
      printcontainer4:this.props.container4,
      printcontainer5:this.props.container5,
      printcontainer6:this.props.container6,
      printcontainer7:this.props.container7,
      printcontainer8:this.props.container8,
      printeta:this.props.vesseleta,
      printforwardingagent:this.props.forwardingagent,
      printpic:this.props.pic,
      printpickup:this.props.pickupyard,
      printpod:this.props.pod,
      printpol:this.props.pol,
      printremark:this.props.remark,
      printrequiretime:this.props.containerrequiretime,
      printrequiredate:this.props.containerrequiredate,
      printshipper:this.props.shippername,
      printshippingaddresss:this.props.shipperaddress,
      printshippingliner:this.props.shippingline,
      printsize1:this.props.size1,
      printsize2:this.props.size2,
      printsize3:this.props.size3,
      printsize4:this.props.size4,
      printtype1:this.props.type1,
      printtype2:this.props.type2,
      printtype3:this.props.type3,
      printtype4:this.props.type4,
      printvolume1:this.props.volume1,
      printvolume2:this.props.volume2,
      printvolume3:this.props.volume3,
      printvolume4:this.props.volume4,
      printvoyage:this.props.vesselvoyage,
      printvoyagename:this.props.voyagename,
      printexport:this.props.importexport,
      printcontactpic:this.props.contactpic,
    }
  
    
    render() {
     // console.log(this.props.importexport);
      return (
        <div class="containerprint">
        <img 
        width="1000px"
        height="1442px"
        alt="..."
        class
        src={require("./../../assets/gif/Rottemplate3.jpg")}
        />
        <span class="importexportprintcss">{this.state.printexport}</span>
        <span class="linerprintcss">{this.state.printbookingno}</span>
         <span class="shippinglinerprintcss">{this.state.printshippingliner}</span>
         <span class="Forwardinglinerprintcss">{this.state.printforwardingagent}</span>
         <span class="Commoditylinerprintcss">{this.state.printcommodity}</span>
         <span class="POLlinerprintcss">{this.state.printpol}</span>
         <span class="PODlinerprintcss">{this.state.printpod}</span>
      <span class="Voyagelinerprintcss">{this.state.printvoyage}/{this.state.printvoyagename}</span>
         <span class="ETAlinerprintcss">{this.state.printeta}</span>
         <span class="Closinglinerprintcss">{this.state.printclosing}</span>
         <span class="Requirelinerprintcss">{this.state.printrequiredate}</span>
         <span class="Requiretimelinerprintcss">{this.state.printrequiretime}</span>
         <span class="Picklinerprintcss">{this.state.printpickup}</span>
         <span class="Shipperprintcss">{this.state.printshipper}</span>
         <span class="PICprintcss">{this.state.printpic}</span>
         <span class="PICcontactcss">{this.state.printcontactpic}</span>
         <span class="shippingaddressprintcss">{this.state.printshippingaddresss}</span>
         <span class="Billprintcss">{this.state.printbilltoparty}</span>
         <span class="volume1printcss">{this.state.printvolume1}</span>
         <span class="volume2printcss">{this.state.printvolume2}</span>
         <span class="volume3printcss">{this.state.printvolume3}</span>
         <span class="volume4printcss">{this.state.printvolume4}</span>
         <span class="size1printcss">{this.state.printsize1}</span>
         <span class="size2printcss">{this.state.printsize2}</span>
         <span class="size3printcss">{this.state.printsize3}</span>
         <span class="size4printcss">{this.state.printsize4}</span>
         <span class="type1printcss">{this.state.printtype1}</span>
         <span class="type2printcss">{this.state.printtype2}</span>
         <span class="type3printcss">{this.state.printtype3}</span>
         <span class="type4printcss">{this.state.printtype4}</span>
         <span class="container1printcss">{this.state.printcontainer1}</span>
         <span class="container2printcss">{this.state.printcontainer2}</span>
         <span class="container3printcss">{this.state.printcontainer3}</span>
         <span class="container4printcss">{this.state.printcontainer4}</span>
         <span class="container5printcss">{this.state.printcontainer5}</span>
         <span class="container6printcss">{this.state.printcontainer6}</span>
         <span class="container7printcss">{this.state.printcontainer7}</span>
         <span class="container8printcss">{this.state.printcontainer8}</span>
         <span class="remarkprintcss">{this.state.printremark}</span>
        </div>
       
         
      );
    }
  }
  
  
  class ComponentToPrint2 extends React.Component {
  
    state= {  
      
      printbilltoparty2:this.props.billingto,
      printbookingno2:this.props.Bookingno,
      printclosing2:this.props.closingdate,
      printcommodity2:this.props.commodity,
      printcontainer12:this.props.container1,
      printcontainer22:this.props.container2,
      printcontainer32:this.props.container3,
      printcontainer42:this.props.container4,
      printcontainer52:this.props.container5,
      printcontainer62:this.props.container6,
      printcontainer72:this.props.container7,
      printcontainer82:this.props.container8,
      printeta2:this.props.vesseleta,
      printforwardingagent2:this.props.forwardingagent,
      printpic2:this.props.pic,
      printpickup2:this.props.pickupyard,
      printpod2:this.props.pod,
      printpol2:this.props.pol,
      printremark2:this.props.remark,
      printrequiretime2:this.props.containerrequiretime,
      printrequiredate2:this.props.containerrequiredate,
      printshipper2:this.props.shippername,
      printshippingaddresss2:this.props.shipperaddress,
      printshippingliner2:this.props.shippingline,
      printsize12:this.props.size1,
      printsize22:this.props.size2,
      printsize32:this.props.size3,
      printsize42:this.props.size4,
      printtype12:this.props.type1,
      printtype22:this.props.type2,
      printtype32:this.props.type3,
      printtype42:this.props.type4,
      printvolume12:this.props.volume1,
      printvolume22:this.props.volume2,
      printvolume32:this.props.volume3,
      printvolume42:this.props.volume4,
      printvoyage2:this.props.vesselvoyage,
      printvesselname:this.props.vesselname,
      printexport2:this.props.importexport,
      printcontactpic2:this.props.contactpic,
    }
  
    
    render() {
      return (
        <div class="image-container">
        
        <img 
        width="1000px"
        height="1442px"
        alt="..."
        class
        style={{marginTop:20,zIndex:-100}}
        src="https://crm.mewahtrans.com.my/api/images/consignote_new.png"
        />
        
        <div class="Consignmentnocss">{this.props.consignmentnoconsign}</div>
        <div class="Containernocss">{this.props.containernoconsign}</div>
        <div class="Sealnonocss">{this.props.sealnoconsign}</div>
        <div class="Typenocss">{this.props.typenoconsign}</div>
        <div class="totalvolumecss">{this.props.totalvolumeconsign}</div>
        <div class="activitydatecreatenewcss">{this.props.activitydatecreatenewconsign}</div>
        <div class="sizenocss">{this.props.sizenoconsign}</div>
       
        <div class="Requestdatenocss">{this.props.requestdatenoconsign}</div>
        <div class="operatorcss">{this.props.operatorconsign}</div>
        <div class="Vsletacss">{this.props.vsletaconsign}</div>
        <div class="bookingcss">{this.props.bookingconsign}</div>
        <div class="vesselnamecss">{this.props.vesselconsign}</div>
        <div class="voyagecss">{this.props.voyageconsign}</div>
        <div class="closingdaynocss">{this.props.closingdaynoconsign}</div>
        <div class="Podcss">{this.props.podconsign}</div>
        <div class="Polcss">{this.props.polconsign}</div>
        <div class="Sideloadercss">{this.props.sideloaderconsign}</div>
        <div class="billtopartycss">{this.props.billtopartyconsign}</div>
        <div class="shippercss">{this.props.shipperconsign}</div>
        <div class="forwardingcss">{this.props.forwardingconsign}</div>
        <div class="pickupaddresscss">{this.props.pickupaddressconsign}</div>
        <div class="deliveryaddresscss">{this.props.deliveryaddressconsign}</div>
        <div class="contactnumbercss">{this.props.contactnumberconsign}</div>      
        <div class="remarkcss">{this.props.remarkconsign}</div>
        <div class="commoditycss">{this.props.commodityconsign}</div>
        
        <div class="drivertruckincss">{this.props.drivertruckinconsign}</div>
        <div class="primemovertruckincss">{this.props.primemoverconsign}</div>
        <div class="trailertruckincss">{this.props.trailertruckinconsign}</div>
        <div class="datetruckincss">{this.props.datetruckinconsign}</div>      
        <img class="imageclasssigncustomertruckin" src={this.props.imageclassassigncustomertruckinconsign} width="100" height="100"/>
        <img class="imageclasssigndrivertruckin" src={this.props.imageclassassigndrivertruckinconsign} width="100" height="100" />
        <div class="customernametruckin">{this.props.customernametruckinconsign}</div>
        <div class="customerphonetruckin">{this.props.customerphonetruckinconsign}</div>
        <div class="customerdatetruckin">{this.props.customerdatetruckinconsign}</div>
  
        <div class="drivertruckoutcss">{this.props.drivertruckoutconsign}</div>
        <div class="primemovertruckoutcss">{this.props.primemovertruckoutconsign}</div>      
        <div class="trailertruckoutcss">{this.props.trailertruckoutconsign}</div>
        <div class="datetruckoutcss">{this.props.datetruckoutconsign}</div>
        <div class="pickupaddresscsstruckout">{this.props.pickupaddresstruckoutconsign}</div>
        <div class="deliveryaddresscsstruckout">{this.props.deliveryaddresstruckoutconsign}</div>
        <div class="datetruckoutcss">{this.props.datetruckoutconsign}</div>
        <img class="imageclasssigncustomertruckout" src={this.props.imageclassconsigncustomertruckout} width="100" height="100" />
        <img class="imageclasssigndrivertruckout" src={this.props.imageclasssigndrivertruckout} width="100" height="100" />
        <div class="customernametruckout">{this.props.customernametruckout}</div>
        <div class="customerphonetruckout">{this.props.customerphonetruckout}</div>
        <div class="customerdatetruckout">{this.props.customerdatetruckout}</div>
       
      </div>
        
       
         
      );
    }
  }

export default filemanager;
